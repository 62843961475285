<template>
  <div class="h-full w-full">
    <Wrapper class="max-w-xl">
      <div class="w-full py-4 mx-auto max-w-sm">
          <h2 class="mb-5 text-2xl text-blue-dark"><b>Cuéntenos su experiencia</b></h2>
          <p class="mt-3 text-sm">Califique de 1 a 5 el funcionamiento de nuestra plataforma, en donde 5 estrellas es el mayor puntaje y 1 estrella el menor.</p>
      </div>
      <div class="w-full py-1 mx-auto flex justify-center gap-3">
          <svg v-for="(start, index) in 5" :key="index" @click="setCalification(index)" width="55" height="52" viewBox="0 0 55 52" fill="transparent" xmlns="http://www.w3.org/2000/svg">
            <path class="calification-star stroke-current text-blue hover:fill-current" d="M30.4658 2.64706L36.9971 15.2318L51.9713 17.7806C54.4405 18.2585 54.8387 21.2056 53.1661 23.1968L42.8115 33.5514L44.8028 47.0122C45.5993 51.3134 43.0505 52.9064 39.4662 51.154L27.3594 44.8617L15.3322 51.154C11.748 52.9064 9.19915 51.3134 9.99566 47.0122L11.9073 33.5514L1.63238 23.1968C-0.0402712 21.2056 0.35798 18.2585 2.82714 17.7806L17.8014 15.2318L24.3327 2.64706C25.5274 -0.140693 29.1914 -0.299994 30.4658 2.64706Z" />
          </svg>
      </div>
      <p v-if="feedback" class="mt-5 text-red-600 text-sm">{{feedback}}</p>
      <div class="w-11/12 py-2 mt-4 mx-auto max-w-sm">
        <a-button type="primary" :block="true" shape="round" @click.native="sendCalification" :loading="loading">
          ENVIAR CALIFICACIÓN
        </a-button>
      </div>
    </Wrapper>
  </div>
</template>

<script>
export default {
    components: {
    },
    data(){
      return {
        calification: '',
        disable: false,
        feedback: '',
        loading: false
      }
    },
    methods: {
      setCalification(points){
        let stars = document.querySelectorAll('svg .calification-star')
        stars.forEach(star => { star.classList.remove('fill-current')})
        for(let i=0;i <= points; i++){
          stars[i].classList.add('fill-current');
        }
        this.calification = points + 1;
      },
      async sendCalification(){
        this.loading = true;
        this.disable = true;
        let form = {
          cod_queja: this.$route.params.complaint_id + "",
          value: this.calification + ""
        }
        this.feedback = ''
        let {data, error} = await this.$api.setCalification(form);
        if(error){
          this.loading = false;
          this.disable = false;
          this.$notification.error({
            message: 'Error',
            description: 'Ocurrio un error. La queja ya se encuentra calificada.'
          });
        }
        if(data){
          this.$notification.success({
            message: 'Notificación',
            description: 'Se calificó correctamente'
          });
          this.$router.go(-1);
        }
      }
    }
}
</script>

<style>
</style>